// TODO: icon for projects without screenshots
// TODO: action item at the bottom: let's talk!
// TODO: add link to resume at the bottom
// TODO: consider more on hover functions
// TODO: move view source to project demo
// TODO: font size and paddings
// TODO: revive demo
import React from "react";
import { Row, Col, Card, Button, Divider } from "antd";
import "./LandingPage.css";
import ProjectPage from "../ProjectPage/ProjectPage";
import { ArrowDownOutlined } from "@ant-design/icons";

const LandingPage = () => {
  const scroll = (name) => {
    const section = document.querySelector("#" + name);
    section.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return (
    <div id="main">
      <section className="section" id="title">
        <h1 className="title">Hao Li</h1>
        <h3 className="job-title">
          Learning Engineer @ Carnegie Learning, Inc.
        </h3>
        <p className="job-desc">
          Full-stack web development and data mining with an education focus
        </p>
        <Row style={{ width: "100%" }}>
          <Col xs={24} sm={12} md={12}>
            <Card className="intro-card" hoverable>
              <h1 className="intro-card-title">Full-Stack Web Developer</h1>
              <Divider />
              <p>
                I have a passion for creating fast and easy-to-use user
                interfaces. With 5 years of experience in full-stack web
                development and 2 years of professional experience, I work
                closely with UX designers and researchers to bring the best
                web-based experience to teachers and learners.
              </p>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Card className="intro-card" hoverable>
              <h1 className="intro-card-title">Data Analyst</h1>
              <Divider />
              <p>
                In the Ed-Tech field, I am also working hard to "harness the
                data revolution". I perform complex data visualization and
                advanced learning analytics such as knowledge tracing with deep
                learning models to make sense of student's learning and help
                drive business decisions.
              </p>
            </Card>
          </Col>
        </Row>
        <button
          onClick={() => scroll("projects")}
          className="check-project-button"
        >
          <ArrowDownOutlined />
          Check out my projects!
        </button>
      </section>
      <section className="section" id="projects">
        <ProjectPage />
      </section>
    </div>
  );
};

export default LandingPage;
