import React from "react";
import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import LandingPage from "./containers/LandingPage/LandingPage";
import Footer from "./components/Footer/Footer";
import { Row, Col } from "antd";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Navbar />
        <Row className="container">
          <Col xs={24} sm={24} md={20} lg={20} className="content">
            <LandingPage />
          </Col>
        </Row>
        <Footer />
      </div>
    );
  }
}

export default App;
