import React, { Component } from "react";

class NavMenu extends Component {
  render() {
    return (
      <nav>
        <ul className="nav-links">
          <li>
            <a href="#main">Intro</a>
          </li>
          <li>
            <a href="#projects">Projects</a>
          </li>
        </ul>
      </nav>
    );
  }
}

export default NavMenu;
