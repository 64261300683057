import React from "react";
// import { Link } from "react-router-dom";

import { TagFilled, TagsFilled } from "@ant-design/icons";
import "antd/dist/antd.css";
import "./Tags.css";

class Tags extends React.Component {
  render() {
    const { tags } = this.props;
    return (
      <div className="tags">
        <span className="tag-icon">
          {tags.length === 1 && <TagFilled />}
          {tags.length > 1 && <TagsFilled />}
        </span>
        {tags.map((tag, index) => (
          <span key={"tags" + index}>
            <span className="tag" key={index}>
              {tag}
            </span>
            {index !== tags.length - 1 && <span> </span>}
          </span>
        ))}
      </div>
    );
  }
}

export default Tags;
