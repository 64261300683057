import React, { Component } from "react";
import NavMenu from "./NavMenu";
import { Drawer, Button } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import "./Navbar.css";

class Navbar extends Component {
  state = {
    current: "",
    visible: false,
  };
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <nav className="menuBar">
        <div className="menuCon">
          <div className="leftMenu">
            <NavMenu mode="horizontal" />
          </div>
          <Button className="barsMenu" type="primary" onClick={this.showDrawer}>
            {/* <span className="barsBtn"></span> */}
            <MenuOutlined />
          </Button>
          <Drawer
            title="Menu"
            placement="right"
            closable={true}
            onClose={this.onClose}
            visible={this.state.visible}
          >
            <NavMenu mode="vertical" />
          </Drawer>
        </div>
      </nav>
    );
  }
}

export default Navbar;
