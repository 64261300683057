import IOWLLandingPage from "../../assets/img/optimized/IOWL-chrome_light_mac.png";

import portfolio from "../../assets/img/optimized/portfolio-frame_chrome_mac_light.png";
import catchGuide from "../../assets/img/optimized/catch-guide-frame_chrome_mac_light.png";
import scoreUp from "../../assets/img/optimized/score-up-habit-builder.jpg";
import cuttleVue from "../../assets/img/optimized/cuttle-vue_frame_chrome_mac_light.png";
import catWallpaper from "../../assets/img/optimized/cat-wallpaper-frame_generic_light.png";

const projects = [
  {
    name: "Portfolio",
    tagline: "Me and my projects.",
    img: portfolio,
    tags: ["JavaScript", "React", "Scss"],
    duration: {
      startMonth: 8,
      startYear: 2019,
      endMonth: 9,
      endYear: 2019,
    },
  },
  {
    name: "Cuttle V3",
    tagline: "The Cuttle card game, remastered!",
    img: cuttleVue,
    tags: [
      "Full Stack",
      "JavaScript",
      "Vue",
      "Vuex",
      "Scss",
      "WebSocket",
      "CyPress",
    ],
    duration: {
      startMonth: 6,
      startYear: 2020,
    },
    team: [
      {
        name: "2",
        link: "",
      },
    ],
    role: "",
    client: "",
    demo: "https://cuttle-v2-and-a-half.herokuapp.com/#/",
  },
  {
    name: "Animal Crossing Catch Guide",
    tagline: "Gotta Catch 'em All!",
    img: catchGuide,
    tags: ["JavaScript", "React", "Python"],
    duration: {
      startMonth: 4,
      startYear: 2020,
      endMonth: 6,
      endYear: 2020,
    },
    role: "",
    client: "",
    source: "https://github.com/lihaobhsfer/my-animal-crossing-catching-guide",
    demo: "https://lihaobhsfer.github.io/my-animal-crossing-catching-guide/",
  },

  {
    name: "ScoreUp Habit Builder",
    tagline: "Habit building with a little more reward.",
    img: scoreUp,
    tags: [
      "Full Stack",
      "MERN",
      "JavaScript",
      "React",
      "MobX",
      "Node.js",
      "Express.js",
      "MongoDB",
    ],
    duration: {
      startMonth: 1,
      startYear: 2020,
    },
    team: [],
    role: "",
    client: "",
    demo: "https://scoreupapp.com/#/login",
    demoCredentials: {
      username: "test",
      password: "test",
    },
  },
  {
    name: "Cat Wallpaper",
    tagline: "Nice creatures to stay on your desktop.",
    img: catWallpaper,
    tags: ["JavaScript", "Electron"],
    duration: {
      startMonth: 2,
      startYear: 2020,
      endMonth: 3,
      endYear: 2020,
    },
    role: "",
    client: "",
    demo: "https://lihaobhsfer.github.io/cartoon-cats/",
  },
  {
    name: "CMU METALS Capstone Project",
    tagline: "Better Writing, Every Day.",
    img: IOWLLandingPage,
    tags: [
      "Full Stack",
      "Capstone Project",
      "JavaScript",
      "React",
      "Redux",
      "Node.js",
      "MongoDB",
    ],
    duration: {
      startMonth: 1,
      startYear: 2019,
      endMonth: 7,
      endYear: 2019,
    },
    // team: [
    //   {
    //     name: "Lauren Zito",
    //     link: "",
    //   },
    //   {
    //     name: "Kim Larson",
    //     link: "",
    //   },
    //   {
    //     name: "Jiaqi Wang",
    //     link: "http://www.jiaqi-wang.me/",
    //   },
    // ],
    role: "Developer",
    // client: "Western Governors University",
  },
  // {
  //   name: "Student Pathway Visualization",
  //   tagline: "Let's see how people approach the problem",
  //   img: "",
  //   tags: ["JavaScript", "Graph Algorithms"],
  //   duration: {
  //     startMonth: 3,
  //     startYear: 2019,
  //     endMonth: 5,
  //     endYear: 2019,
  //   },
  //   role: "",
  //   client: "",
  // },
  // {
  //   name: "Disaster Lab!",
  //   tagline: "Learn safety through disasters.",
  //   img: disasterLab,
  //   tags: ["Educational Games", "Unity", "C#"],
  //   duration: {
  //     startMonth: 3,
  //     startYear: 2019,
  //     endMonth: 5,
  //     endYear: 2019,
  //   },
  //   team: [
  //     {
  //       name: "Qianhui Sun",
  //       link: "",
  //     },
  //     {
  //       name: "Xiaofei Zhou",
  //       link: "",
  //     },
  //   ],
  //   role: "Game Developer",
  //   client: "",
  //   demo: "https://lihaobhsfer.github.io/DisasterLab-landing-page/",
  // },
];

export default projects;
