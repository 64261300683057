import React from "react";

import { Row, Col, Card, Button, Collapse } from "antd";
import Tags from "../../components/Tags/Tags";

import "./ProjectPage.scss";
import projects from "./projects.js";
import { LinkOutlined } from "@ant-design/icons";
const { Panel } = Collapse;

class ProjectPage extends React.Component {
  state = {
    projects: projects,
    tagButtonText: "Show Tags",
    tagClassName: "btns-tags",
    filteredProjects: projects,
    selectedTag: "",
  };
  componentDidMount() {
    let tags = [];
    projects.map((project, index) => {
      tags.push(...project.tags);
      return false;
    });
    console.log(tags);
    let tagsMap = {};
    for (let i = 0; i < tags.length; i++) {
      if (tagsMap[tags[i]] !== undefined) tagsMap[tags[i]]++;
      else tagsMap[tags[i]] = 1;
    }

    console.log(tagsMap);
    this.setState({
      tagsMap: tagsMap,
    });
  }
  toggleSelection(key) {
    console.log(key);
    if (key === this.state.selectedTag) {
      this.setState({
        projects,
        selectedTag: "",
      });
    } else {
      const filteredProjects = projects.filter(
        (project) => project.tags.indexOf(key) !== -1
      );
      this.setState({
        selectedTag: key,
        projects: filteredProjects,
      });
    }
  }

  render() {
    return (
      <div className="project-container">
        <div className="header">
          <h1>Projects</h1>
        </div>
        <Collapse ghost defaultActiveKey={["1"]}>
          <Panel header="Tags" key="1">
            <Row className={this.state.tagClassName}>
              {this.state.tagsMap !== undefined && (
                <div>
                  {Object.keys(this.state.tagsMap).map((key) => (
                    <span key={key}>
                      <button
                        className={
                          this.state.selectedTag === key
                            ? "btn-tag-selected"
                            : "btn-tag"
                        }
                        onClick={() => {
                          this.toggleSelection(key);
                        }}
                      >
                        {key} ({this.state.tagsMap[key]})
                      </button>
                    </span>
                  ))}
                </div>
              )}
            </Row>
          </Panel>
        </Collapse>
        <Row gutter={8} className="projects">
          {this.state.projects.map((project, index) => (
            <Col xs={24} sm={12} md={12} key={index}>
              <Card className="project-card">
                <div className="img-container">
                  <img
                    src={project.img}
                    alt={project.name}
                    className="card-img"
                  />
                </div>

                <div className="card-content">
                  <h2 className="project-name">{project.name}</h2>
                  <p className="tagline">{project.tagline}</p>
                  {project.tags && <Tags tags={project.tags} />}
                  {project.duration && (
                    <p>
                      {project.duration.startYear}.{project.duration.startMonth}{" "}
                      - {project.duration.endYear}.{project.duration.endMonth}
                    </p>
                  )}
                  {project.role && <p>Role: {project.role}</p>}
                  {project.client && <p>Client: {project.client}</p>}
                  {project.team && project.team.length !== 0 && (
                    <p>
                      Team:{" "}
                      {project.team.map((member, index) => (
                        <span key={"teammember" + project.name + index}>
                          {member.name}
                          {index !== project.team.length - 1 && <span>, </span>}
                        </span>
                      ))}
                    </p>
                  )}
                  <Row>
                    {project.demo && (
                      <Col
                        span={24}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Button
                          type="link"
                          style={{ margin: "10px auto 5px" }}
                          href={project.demo}
                          target="blank"
                        >
                          <LinkOutlined />
                          See Demo
                          {project.demoCredentials && (
                            <p>
                              username: {project.demoCredentials.username}{" "}
                              password: {project.demoCredentials.password}
                            </p>
                          )}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}

export default ProjectPage;
