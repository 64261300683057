import React from "react";
// import { Link } from "react-router-dom";
import "antd/dist/antd.css";

const styles = {
  footer: {
    width: "100%",
    height: "4vh",
    margin: "0 auto -4vh",
    padding: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#aaa",
  },
  footerText: {
    margin: "0",
    fontSize: "0.8rem",
  },
};

class Footer extends React.Component {
  render() {
    return (
      <div style={styles.footer}>
        <p style={styles.footerText}>Crafted by Hao Li</p>
      </div>
    );
  }
}

export default Footer;
